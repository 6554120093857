import { Component } from "react";
import { withRouter } from "react-router-dom";
import Category from "../components/Category";
import vaccineImg from "../images/icons/vaccine.png";
import category1Img from "../images/icons/category1.png";
import IconLoading from "../images/icons/Loading.svg";

import {
  AgendamentoContext,
  AgendamentoContextData,
} from "../contexts/agendamento";
import HeaderTitle from "../components/HeaderTitle";

class PageCategory extends Component<any, any> {
  static contextType = AgendamentoContext;

  constructor(props: any) {
    super(props);

    this.state = {
      loadCount: 1,
      agendamentoAssistido: false,
      listCategorias: [],
      listImgs: [],
      hideCategory: false,
    };
  }

  componentDidMount() {
    const agendamentoContext = this.context as AgendamentoContextData;
    agendamentoContext.handleUpdateCurrentStep("exame");
    let _agendamentoAssistido = localStorage.getItem("_pmAgendamentoAssistido");
    if (_agendamentoAssistido !== null) {
      this.setState({ agendamentoAssistido: true });
      window.CustomScrollToTopAssistido();
    } else {
      window.CustomScrollToTop();
    }
    this.loadCategories();
  }

  loadCategories() {
    this.setState({ isLoading: true });
    fetch("/servicoData/GetAllCategoria", { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Error") {
          if (data.code === "001" && this.state.loadCount < 3) {
            this.setState({
              loadCount: this.state.loadCount + 1,
              errorMessage: data.message,
            });
            this.loadCategories();
          }
        } else {
          this.setState({
            listCategorias: data.items,
            listImgs: [null, vaccineImg, category1Img],
          });
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => console.error(error));
  }
  render() {
    return (
      <>
        <HeaderTitle
          title="Compre e agende serviços e vacinas online"
          boxRedTitle="Clinic Farma"
          helperText=""
        />
        {this.state.isLoading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={IconLoading}
              alt="loading"
              className="spiner"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
              }}
            />
          </div>
        )}
        <div className="categories">
          {this.state.listCategorias?.map((categoria: any) => (
            <Category
              key={categoria.id}
              id={categoria.id}
              image={this.state.listImgs[categoria.id]}
              name={categoria.nome}
              details={categoria.descricao}
              subCategoryApi={`/servicoData/GetAllPorCategoria?idCategoria=${categoria.id}`}
              hideCategoryFunction={() =>
                this.setState({ hideCategory: !this.state.hideCategory })
              }
              hideCategoryValue={this.state.hideCategory}
            />
          ))}
        </div>
      </>
    );
  }
}
export default withRouter(PageCategory);
