import { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import {
  AgendamentoContextData,
  AgendamentoContext,
} from "../../contexts/agendamento";

import { FormContainer } from "../../components/Paciente/styles";
import ContinueButton from "../../components/Paciente/ContinueButton";

import FormComponent from "../../components/Paciente/Form";
import Identification from "../../components/Identification";
import IconLoading from "../../images/icons/Loading.svg";
import IconAddUser from "../../images/icons/add-user.svg";
import IconChangeUser from "../../images/icons/change-user.svg";
import iconArrowChecked from "../../images/icons/arrow-checked.svg";

import * as S from "./styles";
import Button from "../../components/Button";
import { ModalSimple } from "../../components/ModalSimple";

import IconTrash from "../../images/icons/icon-trash.png";
import IconSuccess from "../../images/icons/icon-success.svg";
import HeaderTitle from "../../components/HeaderTitle";
import {schedulingService} from "../../services/SchedulingService";

class Paciente extends Component<any, any> {
  static contextType = AgendamentoContext;

  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: false,
      loadCount: 1,
      errorMessage: "",
      clienteNotFound: false,

      email: "",
      cliente: null,
      innerWidth: 0,

      agendamentoAssistido: false,

      linkDadosCliente: "",

      pacienteSelecionado: null,
      showDetails: false,

      idActionMenuPacienteOpen: 0,

      idRemoverPaciente: 0,
      removerPacienteNome: "",
      removerPacienteSexo: "",
      isModalRemoverPacienteOpen: false,
      isModalPacienteRemovido: false,
      titular: null,
      depententes: null,

      saveForm: false,
      reviewPage: false,

      pagamentoOnline: undefined,
      parceiro: undefined,
    };
    this.setCliente = this.setCliente.bind(this);
    this.resizeScreen = this.resizeScreen.bind(this);
    this.selecionarPaciente = this.selecionarPaciente.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.trocarPaciente = this.trocarPaciente.bind(this);
    this.clickButtonBack = this.clickButtonBack.bind(this);
    this.closeMenuAcoes = this.closeMenuAcoes.bind(this);
    this.handleEnableButton = this.handleEnableButton.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.checkOnlinePaymentEnabled = this.checkOnlinePaymentEnabled.bind(this);
    this.handleButtonText = this.handleButtonText.bind(this);
  }

  handleButtonText(){
    if(this.state.pagamentoOnline)
      return "Prosseguir com o pagamento"

    if(this.state.parceiro || !this.state.pagamentoOnline)
      return "Concluir agendamento"
  }

  async checkOnlinePaymentEnabled() {
    let lojaStr = localStorage.getItem("_pmLoja");
    if (lojaStr === null) {
      this.props.history.push("/hubdesaude/loja");
      return;
    }

    let dataStr = localStorage.getItem("_pmDataSelecionada");
    if (dataStr === null) {
      this.props.history.push("/hubdesaude/agenda");
      return;
    }

    const loja = JSON.parse(lojaStr)
    const dataSelecionada = JSON.parse(dataStr);

    const onlinePaymentEnabled = await schedulingService.verifyOnlinePaying(
      loja.codigo,
      dataSelecionada.parceiroLojaSalaId
    );

    localStorage.setItem("_pmPagamentoOnline", `${onlinePaymentEnabled}`);
    localStorage.setItem("_pmParceiro", `${(dataSelecionada.parceiroLojaSalaId !== null)}`);

    this.setState({
      ...this.state,
      pagamentoOnline: onlinePaymentEnabled,
      parceiro: dataSelecionada.parceiroLojaSalaId !== null
    })
  }

  async componentDidMount() {
    await this.checkOnlinePaymentEnabled()

    const agendamentoContext = this.context as AgendamentoContextData;
    agendamentoContext.handleUpdateCurrentStep("paciente");

    //if (agendamentoContext.cliente.nome) {
    //    this.setCliente(agendamentoContext.cliente);
    //}

    //GUARDAR STEP
    let pathname = this.props.location.pathname;
    let path = pathname.substring(pathname.lastIndexOf("/"));
    localStorage.setItem("_pmLastStep", path);
    //GUARDAR STEP

    this.setState({ innerWidth: window.innerWidth });
    window.addEventListener("resize", (event) => {
      this.resizeScreen();
    });

    let _agendamentoAssistido = localStorage.getItem("_pmAgendamentoAssistido");
    if (_agendamentoAssistido !== null) {
      this.setState({ agendamentoAssistido: true });
      window.CustomScrollToTopAssistido();
    } else {
      const vtexclientemail = localStorage.getItem("_pmVtexclientemail");
      if (vtexclientemail === null)
        this.props.history.push("/hubdesaude/agenda");

      this.setState({ email: vtexclientemail });

      window.CustomScrollToTop();
    }

    const apiUrl = `clienteData/LinkDadosCliente`;
    fetch(apiUrl, { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Success")
          this.setState({ linkDadosCliente: data.linkDadosCliente });
      });

    window.addEventListener("click", this.closeMenuAcoes);

    //VERIFICAR PACIENTE LOCALSTORAGE
    const pacienteAssistido = localStorage.getItem("_pmPacienteAssistido");
    if (pacienteAssistido !== null)
      this.setCliente(JSON.parse(pacienteAssistido));
  }

  closeMenuAcoes(e: MouseEvent) {
    //@ts-ignore
    if (e.target.nodeName !== "svg" && e.target.nodeName !== "path") {
      if (this.state.idActionMenuPacienteOpen !== 0)
        this.setState({ idActionMenuPacienteOpen: 0 });
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.email !== this.state.email) {
      this.loadCliente(this.state.email);
    }

    const vtexclientecpf = localStorage.getItem("_pmVtexclientecpf");
    if (vtexclientecpf === null) return;

    this.setState({ cliente: { ...this.state.cliente, cpf: vtexclientecpf } });
    localStorage.removeItem("_pmVtexclientecpf");
  }

  loadCliente = (email: string) => {
    console.log(`Buscando Cliente ${this.state.loadCount}x`);
    this.setState({ isLoading: true });

    const apiUrl = `pacienteData/ListarPacientes?email=${email}`;
    this.setState({ isLoading: true });
    fetch(apiUrl, { cache: "no-cache" })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Error") {
          if (data.code === "001" && this.state.loadCount < 3) {
            this.setState({
              loadCount: this.state.loadCount + 1,
              errorMessage: data.message,
            });
            this.loadCliente(email);
          } else {
            this.setState({ clienteNotFound: true });
          }
        } else {
          //this.setState({ cliente: data.masterData });
          if (
            data.paciente.titular &&
            (data.paciente.titular.cpf === "" || !data.paciente.titular.cpf)
          ) {
            localStorage.removeItem("_pmTitular");
            this.props.history.push(
              `/hubdesaude/adicionar-paciente?vtexclientemail=${data.paciente.titular.email}&codigoClienteVinculado=${data.paciente.titular.codigoClienteVinculado}&primeiroAcesso=true`
            );
          } else if (data.paciente.titular) {
            this.setState({ titular: data.paciente.titular });
            this.setState({ dependentes: data.paciente.dependentes });
            localStorage.setItem(
              "_pmTitular",
              JSON.stringify(data.paciente.titular)
            );
          }
        }
        this.setState({ isLoading: false });
      });
  };

  setCliente = (cliente: any) => {
    if (cliente !== null) {
      this.setState({ cliente: cliente });
      this.setState({
        pacienteSelecionado: {
          nome: cliente.nome.toUpperCase(),
          email: cliente.email,
          cpf: cliente.cpf,
          telefone: cliente.telefone,
          dataNascimento: cliente.dataNascimento,
          sexo: cliente.sexo,
          codigoCliente: cliente.codigoCliente,
        },
      });
    } else {
      this.setState({ showDetails: false, pacienteSelecionado: null });
    }
    this.setState({ showDetails: true });
  };

  resizeScreen() {
    this.setState({ innerWidth: window.innerWidth });
  }

  selecionarPaciente(paciente: any) {
    this.setState(
      {
        pacienteSelecionado: {
          id: paciente.id,
          nome: paciente.nome.toUpperCase(),
          email: paciente.email,
          cpf: paciente.cpf,
          telefone: paciente.telefone,
          dataNascimento: paciente.dataNascimento,
          sexo: paciente.sexo,
          codigoCliente: paciente.codigoCliente,
        },
      },
      () => {
        this.handleNextStep(this.state.pacienteSelecionado);
      }
    );
  }

  validatePacienteSelecionado = (paciente: any): boolean => {
    const validNome = paciente?.nome?.length > 3;
    const validTelefone = paciente?.telefone?.length > 10;
    const validCpf = paciente?.cpf?.length > 13;
    const validEmail = paciente?.email?.length > 8;
    const validGenero = paciente?.sexo?.length > 0;
    const validDataNascimento = paciente?.dataNascimento?.length > 9;

    if (
      validNome &&
      validCpf &&
      validGenero &&
      validDataNascimento &&
      validEmail &&
      validTelefone
    )
      return true;
    else return false;
  };

  removerPaciente() {
    fetch("pacienteData/Delete/" + this.state.titular.id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: this.state.idRemoverPaciente }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Success") {
          this.setState({
            isModalRemoverPacienteOpen: false,
            isModalPacienteRemovidoOpen: true,
            idRemoverPaciente: 0,
          });
          this.loadCliente(this.state.titular.email);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  nextStep() {
    if (this.state.pacienteSelecionado?.nome) {
      this.setState({ showDetails: true });
    }
  }

  trocarPaciente() {
    if (this.state.pacienteSelecionado?.nome) {
      this.setState({
        showDetails: false,
        pacienteSelecionado: null,
        reviewPage: false,
      });
    }
  }

  clickButtonBack() {
    const agendamentoAssistido = this.state.agendamentoAssistido;
    const showDetails = this.state.showDetails;
    this.setState({ reviewPage: false });

    if (agendamentoAssistido || !showDetails) {
      localStorage.removeItem("_pmPacienteAssistido");

      const agendamentoContext = this.context as AgendamentoContextData;
      agendamentoContext.handleUpdateCliente({});
      agendamentoContext.handleCompleteStep("agenda");

      this.setCliente(null);

      if (!showDetails) this.props.history.push("/hubdesaude/agenda");
    } else if (!agendamentoAssistido && showDetails)
      this.setState({ showDetails: false });
  }

  handleEnableButton() {
    this.setState({ saveForm: false });
  }

  // handleDeletePatient(item: any) {
  //     this.setState({ removerPacienteNome: item.nome, isModalRemoverPacienteOpen: true, idRemoverPaciente: item.id })
  // }

  handleNextStep(paciente: any) {
    window.CustomScrollToTop();
    if (this.validatePacienteSelecionado(paciente)) {
      this.setState({ reviewPage: true });
      this.nextStep();
    } else
      toast.error(
        "Preencha todos os campos do formulário do paciente selecionado."
      );
  }

  render() {
    return (
      <>
        <HeaderTitle
          title="Compre e agende serviços e vacinas online"
          boxRedTitle="Clinic Farma"
          helperText={
            this.state.reviewPage
              ? `Revise as informações do pedido antes de realizar o agendamento`
              : "Escolha ou adicione a pessoa que usará o(s) serviço(s) selecionado(s)"
          }
        />

        <S.Container>
          {(!this.state.agendamentoAssistido ||
            this.state.pacienteSelecionado !== null) && (
            <S.HeaderSubCategory>
              <S.ContainerButton>
                <S.TitleSlick>
                  &nbsp;
                  {this.state.showDetails && (
                    <div className={"finishBtn"}>
                      <button
                        disabled={this.state.isLoading}
                        onClick={() => {
                          window.CustomScrollToTop();
                          this.setState({ saveForm: true });
                        }}
                      >
                        {this.handleButtonText()}
                      </button>
                    </div>
                  )}
                </S.TitleSlick>
                <S.ButtonActionsContainer>
                  <S.ButtonActions>
                    <button
                      className="button-back-sub-category"
                      onClick={this.clickButtonBack}
                    >
                      Voltar
                    </button>
                    {this.state.showDetails ? (
                      <ContinueButton
                        disabled={this.state.isLoading}
                        texto={this.handleButtonText()}
                        nextButtonActive={true}
                        onClick={() => {
                          window.CustomScrollToTop();
                          this.setState({ saveForm: true });
                        }}
                      />
                    ) : (
                      <ContinueButton
                        texto={"Continuar"}
                        nextButtonActive={this.state.pacienteSelecionado?.nome}
                        onClick={() =>
                          this.handleNextStep(this.state.pacienteSelecionado)
                        }
                        disabled={!this.state.pacienteSelecionado}
                      />
                    )}
                  </S.ButtonActions>
                </S.ButtonActionsContainer>
              </S.ContainerButton>
            </S.HeaderSubCategory>
          )}

          {!this.state.isLoading &&
            !this.state.isModalRemoverPacienteOpen &&
            !this.state.isModalPacienteRemovidoOpen && (
              <>
                {!this.state.agendamentoAssistido && (
                  <div className="container-header">
                    {this.state.showDetails ? (
                      <>
                        <S.HeaderDetails>
                          <h2>Informações do pedido</h2>
                          <Button
                            inverted
                            width="264px"
                            height="43px"
                            icon={IconChangeUser}
                            onClick={this.trocarPaciente}
                          >
                            Trocar paciente
                          </Button>
                        </S.HeaderDetails>
                      </>
                    ) : (
                      <S.HeaderDetails>
                        <h2
                          className="lista-paciente-hidden"
                          style={{ opacity: 0 }}
                        >
                          Lista de pacientes
                        </h2>
                        <Button
                          inverted
                          width="264px"
                          height="43px"
                          icon={IconAddUser}
                          onClick={() =>
                            this.props.history.push(
                              "/hubdesaude/adicionar-paciente"
                            )
                          }
                        >
                          Adicionar paciente
                        </Button>
                      </S.HeaderDetails>
                    )}
                  </div>
                )}

                {this.state.agendamentoAssistido &&
                  !this.state.isLoading &&
                  this.state.pacienteSelecionado === null && (
                    <Identification
                      setCliente={this.setCliente}
                      linkDadosCliente={this.state.linkDadosCliente}
                      setReviewPage={() => this.setState({ reviewPage: true })}
                    />
                  )}

                {!this.state.agendamentoAssistido && !this.state.showDetails && (
                  <div className="lista-clientes">
                    {this.state.titular && (
                      <S.CardCliente>
                        <div
                          className="informacoes-click"
                          onClick={() =>
                            this.selecionarPaciente(this.state.titular)
                          }
                        >
                          <S.RadioButton
                            isChecked={
                              this.state.pacienteSelecionado &&
                              this.state.titular.id ===
                                this.state.pacienteSelecionado.id
                            }
                          >
                            <img src={iconArrowChecked} alt="Selecionar" />
                          </S.RadioButton>
                          <div>
                            <p className="cliente-nome">
                              {this.state.titular?.nome}
                              <span> Titular</span>
                            </p>
                            <p>
                              Email: <span>{this.state.titular?.email}</span>
                            </p>
                            <div className="inline-info">
                              <p>
                                CPF: <span>{this.state.titular?.cpf}</span>
                              </p>
                              <p>
                                Telefone:{" "}
                                <span>{this.state.titular?.telefone}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <svg
                          onClick={() => {
                            if (this.state.idActionMenuPacienteOpen) {
                              this.setState({ idActionMenuPacienteOpen: 0 });
                            } else {
                              this.setState({
                                idActionMenuPacienteOpen:
                                  this.state.titular?.id,
                              });
                            }
                          }}
                          width="4"
                          height="14"
                          viewBox="0 0 4 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.00016 3.66732C2.91683 3.66732 3.66683 2.91732 3.66683 2.00065C3.66683 1.08398 2.91683 0.333984 2.00016 0.333984C1.0835 0.333984 0.333496 1.08398 0.333496 2.00065C0.333496 2.91732 1.0835 3.66732 2.00016 3.66732ZM2.00016 5.33398C1.0835 5.33398 0.333496 6.08398 0.333496 7.00065C0.333496 7.91732 1.0835 8.66732 2.00016 8.66732C2.91683 8.66732 3.66683 7.91732 3.66683 7.00065C3.66683 6.08398 2.91683 5.33398 2.00016 5.33398ZM0.333496 12.0007C0.333496 11.084 1.0835 10.334 2.00016 10.334C2.91683 10.334 3.66683 11.084 3.66683 12.0007C3.66683 12.9173 2.91683 13.6673 2.00016 13.6673C1.0835 13.6673 0.333496 12.9173 0.333496 12.0007Z"
                            fill="#7B8FA5"
                          />
                        </svg>

                        {this.state.idActionMenuPacienteOpen ===
                          this.state.titular?.id && (
                          <div className="menu-acoes">
                            <button
                              className="btn-editar"
                              onClick={() =>
                                this.props.history.push(
                                  `/hubdesaude/adicionar-paciente?id=${this.state.titular?.id}&codigoClienteVinculado=${this.state.titular?.codigoClienteVinculado}`
                                )
                              }
                            >
                              Editar
                            </button>
                          </div>
                        )}
                      </S.CardCliente>
                    )}

                    {this.state.dependentes?.map((item: any) => {
                      if (item) {
                        return (
                          <S.CardCliente key={item.key}>
                            <div
                              className="informacoes-click"
                              onClick={() => this.selecionarPaciente(item)}
                            >
                              <S.RadioButton
                                isChecked={
                                  this.state.pacienteSelecionado &&
                                  item.id === this.state.pacienteSelecionado.id
                                }
                              >
                                <img src={iconArrowChecked} alt="Selecionar" />
                              </S.RadioButton>
                              <div>
                                <p className="cliente-nome">
                                  {item.nome} <span>Dependente</span>
                                </p>
                                <p>
                                  Email: <span>{item.email}</span>
                                </p>
                                <div className="inline-info">
                                  <p>
                                    CPF: <span>{item.cpf}</span>
                                  </p>
                                  <p>
                                    Telefone: <span>{item.telefone}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <svg
                              onClick={() => {
                                if (this.state.idActionMenuPacienteOpen) {
                                  this.setState({
                                    idActionMenuPacienteOpen: 0,
                                  });
                                } else {
                                  this.setState({
                                    idActionMenuPacienteOpen: item.id,
                                  });
                                }
                              }}
                              width="4"
                              height="14"
                              viewBox="0 0 4 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2.00016 3.66732C2.91683 3.66732 3.66683 2.91732 3.66683 2.00065C3.66683 1.08398 2.91683 0.333984 2.00016 0.333984C1.0835 0.333984 0.333496 1.08398 0.333496 2.00065C0.333496 2.91732 1.0835 3.66732 2.00016 3.66732ZM2.00016 5.33398C1.0835 5.33398 0.333496 6.08398 0.333496 7.00065C0.333496 7.91732 1.0835 8.66732 2.00016 8.66732C2.91683 8.66732 3.66683 7.91732 3.66683 7.00065C3.66683 6.08398 2.91683 5.33398 2.00016 5.33398ZM0.333496 12.0007C0.333496 11.084 1.0835 10.334 2.00016 10.334C2.91683 10.334 3.66683 11.084 3.66683 12.0007C3.66683 12.9173 2.91683 13.6673 2.00016 13.6673C1.0835 13.6673 0.333496 12.9173 0.333496 12.0007Z"
                                fill="#7B8FA5"
                              />
                            </svg>

                            {this.state.idActionMenuPacienteOpen ===
                              item.id && (
                              <div className="menu-acoes">
                                <button
                                  className="btn-editar"
                                  onClick={() =>
                                    this.props.history.push(
                                      `/hubdesaude/adicionar-paciente?id=${item.id}`
                                    )
                                  }
                                >
                                  Editar
                                </button>
                                <button
                                  className="btn-remover-paciente"
                                  onClick={() => {
                                    window.CustomScrollToTop();
                                    this.setState({
                                      removerPacienteNome: item.nome,
                                      isModalRemoverPacienteOpen: true,
                                      idRemoverPaciente: item.id,
                                      idActionMenuPacienteOpen: 0,
                                    });
                                  }}
                                >
                                  Remover paciente
                                </button>
                              </div>
                            )}
                          </S.CardCliente>
                        );
                      }
                    })}
                  </div>
                )}
              </>
            )}
        </S.Container>

        {this.state.isLoading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={IconLoading}
              alt="loading"
              className="spiner"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
              }}
            />
          </div>
        )}

        {this.state.showDetails && (
          <div className="container" style={{ padding: "0" }}>
            {!this.state.isLoading && this.state.loadCount === 3 && (
              <div className="row">
                <div className="w-100">
                  <p>{this.state.errorMessage}</p>
                </div>
              </div>
            )}
            <div>
              {this.state.isLoading && (
                <div className="row justifyCenter">
                  <img
                    src={IconLoading}
                    alt="loading"
                    className="spiner"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                </div>
              )}
              {!this.state.isLoading &&
                this.state.pacienteSelecionado !== null && (
                  <FormContainer>
                    <FormComponent
                      selectedDate={localStorage.getItem("_pmDataSelecionada")}
                      salvarFormulario={this.state.saveForm}
                      cliente={this.state.pacienteSelecionado}
                      linkDadosCliente={this.state.linkDadosCliente}
                      handleEnableButton={this.handleEnableButton}
                    />
                  </FormContainer>
                )}
            </div>
          </div>
        )}

        {window.innerWidth > 1023 && (
          <ModalSimple
            Height="309px"
            isOpen={this.state.isModalRemoverPacienteOpen}
            icon={IconTrash}
            title="Remover paciente"
            message={
              <span style={{ maxWidth: 376 }}>
                Tem certeza que deseja remover{" "}
                <strong> {this.state.removerPacienteNome} </strong> da sua lista
                de pacientes?
              </span>
            }
            confirm={() => {
              this.removerPaciente();
            }}
            onRequestClose={() =>
              this.setState({ isModalRemoverPacienteOpen: false })
            }
            imgStyle={{
              width: "38.49px",
              height: "44px",
            }}
            isIconClose={true}
            showCancel={true}
            continueButtonText="Confirmar"
            widthButtonClose="180px"
            widthButtonContinue="180px"
            heightButton="43px"
            className="modal-remover-paciente"
          />
        )}

        {window.innerWidth <= 1024 && (
          <ModalSimple
            isOpen={this.state.isModalRemoverPacienteOpen}
            icon={IconTrash}
            title="Remover paciente"
            message={
              <span style={{ maxWidth: 376 }}>
                Tem certeza que deseja remover{" "}
                <strong> {this.state.removerPacienteNome} </strong> da sua lista
                de pacientes?
              </span>
            }
            confirm={() => {
              this.removerPaciente();
            }}
            onRequestClose={() =>
              this.setState({ isModalRemoverPacienteOpen: false })
            }
            imgStyle={{
              width: "38.49px",
              height: "44px",
            }}
            isIconClose={true}
            showCancel={true}
            continueButtonText="Confirmar"
            widthButtonClose="133px"
            widthButtonContinue="133px"
            heightButton="43px"
            className="modal-remover-paciente"
          />
        )}

        <ModalSimple
          isOpen={this.state.isModalPacienteRemovidoOpen}
          icon={IconSuccess}
          title="Paciente removido"
          message={
            <span style={{ maxWidth: 376 }}>
              O paciente <strong>{this.state.removerPacienteNome}</strong> foi
              removido com sucesso de sua lista de pacientes.
            </span>
          }
          confirm={() => this.setState({ isModalPacienteRemovidoOpen: false })}
          onRequestClose={() =>
            this.setState({ isModalPacienteRemovidoOpen: false })
          }
          imgStyle={{
            width: "38.49px",
            height: "44px",
          }}
          isIconClose={true}
          continueButtonText="Fechar"
          widthButtonContinue="179px"
          heightButton="43px"
          className="modal-paciente-removido"
        />
      </>
    );
  }
}
export default withRouter(Paciente);
