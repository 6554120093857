import * as S from "./styles";
import iconSelectImg from "../../../images/icons/icon-select.svg";
import iconArrowUp from "../../../images/icons/icon-arrow-up.svg";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import Exame from "../../../pages/Exame";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../Exame/ConfButton";
import ModalPreRequisitos from "../../ModalPreRequisitos";
import IconLoading from "../../../images/icons/Loading.svg";

import { AgendamentoContext } from "../../../contexts/agendamento";
import HeaderTitle from "../../HeaderTitle";
import IServico from "../../../types/Servico";
import { clearDataAfter15min } from "../../../utils/utils";

interface SubCategoriaData {
  id: number;
  nome: string;
  servicos: IServico[];
}
interface subCategoryFilteredData {
  id: number;
  nome: string;
}

// interface subCategoryVisibleData {
//     id: number;
//     nome: string;
// }

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default function SubCategory() {
  // const [subCategorySelected, setSubCategorySelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idSubCategorySelected, setIdSubCategorySelected] = useState(0);
  const [idCategorySelected, setIdCategorySelected] = useState(0);
  // const [count, setCount] = useState(2)
  const [subCategoryFiltered, setSubCategoryFiltered] = useState<
    subCategoryFilteredData[]
  >([]);

  const [subCategoria, setSubCategoria] = useState<SubCategoriaData[]>([]);
  const [subCategoriaInit, setSubCategoriaInit] = useState<SubCategoriaData[]>(
    []
  );
  // const [subCategoryVisible, setSubCategoryVisible] = useState<subCategoryVisibleData[]>([]);
  const [nextButtonActive, setNextbuttonActive] = useState(false);
  const [preRequisitosOpen, setPreRequisitosOpen] = useState(false);
  const [flagBtn, setFlagBtn] = useState(true);
  const [titleSubCategory, setTitleSubCategory] = useState("");
  const [idFilter, setIdFilter] = useState("DEFAULT");
  const [loadCount, setLoadCount] = useState(0);
  const [categoryText, setCategoryText] = useState("");
  const query = useQuery();

  let history = useHistory();
  const agendamentoContext = useContext(AgendamentoContext);

  useEffect(() => {
    let [exists, categoryId, subCategoryId] = checkQuery();
    if (categoryId !== -1 && idCategorySelected !== categoryId) {
      setIdCategorySelected(categoryId);
    }

    let cat: any = query.get("csub");
    cat = parseInt(cat);

    setCategoryText(
      cat === 2 ? "serviço de vacinação" : "serviços farmacêuticos"
    );

    loadData();
  }, []);

  useEffect(() => {
    if (subCategoriaInit.length > 1) loadData();
  }, [subCategoriaInit]);

  useEffect(() => {
    if (idCategorySelected > 0) {
      localStorage.removeItem("_subCategorias");
      loadData();
    }
  }, [idCategorySelected]);

  useEffect(() => {
    if (agendamentoContext.quantidadeServicos > 0) {
      setNextbuttonActive(true);
    }
  }, [agendamentoContext.exames]);
  /*
    function filtrar(selecionado: any) {
        //return array = selecionado;
        const subCategoriasFiltradas = subCategoria.filter((value) => value.id == selecionado);
        if (selecionado != "DEFAULT") {
            setSubCategoryFiltered(subCategoriasFiltradas)
            //setIdSubCategorySelected(subCategoriasFiltradas[0].id)
            // console.log(subCategoriasFiltradas[0].id)
            //console.log(selecionado)
            // handleIdSubcategoria(subCategoriasFiltradas[0].id)
            // console.log("teste1",subCategoriasFiltradas[0].id)
            // console.log("teste1",selecionado)
            setFlagBtn(false)
            handleIdSubcategoria(-1)
            setTimeout(() => {
                //console.log("timeout")
                setIdSubCategorySelected(subCategoriasFiltradas[0].id)
            }, 1);
        }
        else {
            setSubCategoryFiltered([subCategoria[0], subCategoria[1]]);
            handleIdSubcategoria(-1)
            setTimeout(() => {
                //console.log("timeout")
                setIdSubCategorySelected(1)
            }, 1);
            setFlagBtn(true)
        }


    }*/

  const loadData = () => {
    let dataFormat: SubCategoriaData[];
    const data = localStorage.getItem("_subCategorias");
    let [exists, categoryId, subCategoryId] = checkQuery();

    if (!data) {
      if (exists) handleCategoriesAndSub(categoryId, subCategoryId);
      else handleIdSubcategoria(subCategoryId);
      return;
    }

    dataFormat = JSON.parse(data);
    setSubCategoria(dataFormat);
    setSubCategoryFiltered(dataFormat);

    let _agendamentoAssistido = localStorage.getItem("_pmAgendamentoAssistido");
    if (_agendamentoAssistido !== null) {
      window.CustomScrollToTopAssistido();
    } else {
      window.CustomScrollToTop();
    }

    const tituloSubcategoriaFromMemory = localStorage.getItem(
      "_tituloSubCategorias"
    );
    if (tituloSubcategoriaFromMemory != null) {
      const format = JSON.parse(tituloSubcategoriaFromMemory);
      setTitleSubCategory(format);
    } else history.push("/hubdesaude/exame");

    const subCatId = localStorage.getItem("_subCatHomeSelected");
    if (subCatId) {
      const id = Number.parseInt(JSON.parse(subCatId));
      !Number.isNaN(id) && setIdSubCategorySelected(id);
      anchoringSubcategory(id);
    } else {
      const servicoSelecionadoHome = localStorage.getItem(
        "_ApmServicosVitrine"
      );
      if (servicoSelecionadoHome !== null) {
        setFlagBtn(false);

        const servicoSelecionadoHomeFormat = JSON.parse(servicoSelecionadoHome);

        const subCateSelecionada = dataFormat.find((subCat) =>
          subCat.servicos.some(
            (serv) => serv.id === servicoSelecionadoHomeFormat[0].id
          )
        );

        anchoringSubcategory(subCateSelecionada?.id);
        return (
          subCateSelecionada && setIdSubCategorySelected(subCateSelecionada.id)
        );
        // console.log('servico selecionado', t, servicoSelecionadoHomeFormat);

        // for (var i = 0; i < dataFormat.length; ++i) {
        //     let selecionado = dataFormat[i].servicos.filter((value: any) => { return value.id === servicoSelecionadoHomeFormat[0].id; });
        //     if (selecionado.length > 0) {
        //         setIdSubCategorySelected(dataFormat[i].id);
        //         break;
        //     }
        // }
      }
    }

    /*/SE FOI SELECIONADO SERVICO NA HOME, FILTRA A PRIMEIRA CATEGORIA QUE CONTEM ELE
        const servicoSelecionadoHome = localStorage.getItem('_ApmServicosVitrine');
        if (servicoSelecionadoHome !== null) {
            let servicoSelecionadoHomeFormat = JSON.parse(servicoSelecionadoHome);
            for (var i = 0; i < dataFormat.length; ++i) {
                let selecionado = dataFormat[i].servicos.filter((value: any) => { return value.id === servicoSelecionadoHomeFormat[0].id; });
                if (selecionado.length > 0) {
                    setIdFilter(dataFormat[i].id.toString());
                    break;
                }
            }
        }*/
  };

  const checkQuery = (): [boolean, number, number] => {
    let categoriaSubCategoria = query.get("csub");
    if (!categoriaSubCategoria) return [false, -1, -1];
    else {
      let auxNumberCategory = categoriaSubCategoria.split("|")[0];
      let auxNumberSubCategory = categoriaSubCategoria.split("|")[1];
      if (
        !Number.isNaN(auxNumberCategory) &&
        Number(auxNumberCategory) > 0 &&
        !Number.isNaN(auxNumberSubCategory) &&
        Number(auxNumberSubCategory) > 0
      )
        return [true, Number(auxNumberCategory), Number(auxNumberSubCategory)];

      return [false, -1, -1];
    }
  };

  function continueButton() {
    const sServicos = localStorage.getItem("_ApmServicos");
    let arrayServicos: any[] = [];
    if (sServicos !== null) arrayServicos = JSON.parse(sServicos);

    const servicos = arrayServicos.filter(function (row: any) {
      return row.preRequisito !== "";
    });

    if (servicos.length > 0) {
      window.CustomScrollToTop();

      setPreRequisitosOpen(!preRequisitosOpen);
    } else {
      nextStep();
    }
  }

  function nextStep() {
    if (nextButtonActive) {
      agendamentoContext.handleCompleteStep("exame");
      window.CustomScrollToTop();
      history.push("/hubdesaude/loja");
    }
  }

  function handleButtonToContinue(value: boolean) {
    setNextbuttonActive(value);
  }

  function handleIdSubcategoria(id: number) {
    //provavelmente o erro está aqui
    // console.log("console subcat",idSubCategorySelected)
    if (idSubCategorySelected !== id) {
      setIdSubCategorySelected(id);
      //console.log("console id", id)
    } else {
      setIdSubCategorySelected(0);
    }
  }

  function BackBtn() {
    //history.goBack
    history.push("../hubdesaude/exame");
  }

  const handleCategoriesAndSub = async (
    categoryId: number,
    subCategoryId: number
  ) => {
    setLoading(true);
    await fetch(`/servicoData/GetAllPorCategoria?idCategoria=${categoryId}`, {
      cache: "no-cache",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "Success") {
          localStorage.setItem("_subCategorias", JSON.stringify(data.items));
          setSubCategoriaInit(data.items);
          clearDataAfter15min();

          const id1 = "farmacêuticos";
          const id2 = "de vacinação";

          localStorage.setItem(
            "_tituloSubCategorias",
            JSON.stringify(categoryId === 1 ? id1 : id2)
          );
          handleIdSubcategoria(subCategoryId);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadCount(3);
      })
      .finally(() => setLoading(false));
  };

  const anchoringSubcategory = (key?: number) => {
    let categoriaSubCategoria = query.get("csub");
    if (!categoriaSubCategoria) return `#subcategoria${key}`;

    let auxNumberCategory = categoriaSubCategoria.split("|")[0];
    let auxNumberSubCategory = categoriaSubCategoria.split("|")[1];
    if (
      !Number.isNaN(auxNumberCategory) &&
      Number(auxNumberCategory) > 0 &&
      ((!Number.isNaN(auxNumberSubCategory) &&
        Number(auxNumberSubCategory) > 0) ||
        (!Number.isNaN(key) && Number(key) > 0))
    )
      history.location.search = `?csub=${auxNumberCategory}|${
        key ?? auxNumberSubCategory
      }`;

    history.push(history.location);
  };

  return (
    <>
      <HeaderTitle
        title="Compre e agende serviços e vacinas online"
        boxRedTitle="Clinic Farma"
        helperText={`Selecione o tipo de ${categoryText}`}
      />
      <S.Container>
        {/* <ModalFormReagendamento
                isOpen={isModalFormReagendamentoOpen}
                onRequestClose={() => setIsModalFormReagendamentoOpen(false)}
                onContinue={(dateTime: string) => {
                    setDataReagendar(dateTime);
                    setIsModalFormReagendamentoOpen(false);
                    setIsModalMotivoReagendamentoOpen(true);
                }}
            /> */}

        {preRequisitosOpen && (
          <ModalPreRequisitos
            isOpen={preRequisitosOpen}
            onRequestClose={() => setPreRequisitosOpen(!preRequisitosOpen)}
            // toContinue={nextStep}
          />
        )}

        {window.innerWidth > 1025 && (
          <S.HeaderSubCategory>
            <S.ContainerButton>
              <S.TitleSlick>
                {/* <span>Selecione o tipo de serviços {titleSubCategory}:</span> */}
                &nbsp;
              </S.TitleSlick>
              <S.ButtonActions>
                <button className="button-back-sub-category" onClick={BackBtn}>
                  Voltar
                </button>
                <Button
                  disabled={agendamentoContext.exames.length === 0}
                  nextButtonActive={nextButtonActive}
                  context={agendamentoContext}
                  onClick={() => {
                    continueButton();
                  }}
                />
              </S.ButtonActions>
            </S.ContainerButton>
          </S.HeaderSubCategory>
        )}
        {loading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={IconLoading}
              alt="loading"
              className="spiner"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
              }}
            />
          </div>
        )}
        {!loading && (!preRequisitosOpen || window.innerWidth > 1024) && (
          <>
            <div className="contain-category">
              <div
                className="select-category"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label style={{ margin: 0 }} htmlFor="subCategory">
                  Categoria
                </label>
                <select
                  defaultValue={"DEFAULT"}
                  name="subCategory"
                  id="subCategory"
                  onChange={(e) => setIdFilter(e.target.value)}
                  value={idFilter}
                >
                  <option value="DEFAULT" className="default-value-option">
                    Todas as Categorias
                  </option>
                  {subCategoria?.map((e, key) => {
                    return (
                      <option key={e.id} value={e.id}>
                        {e.nome}
                      </option>
                    );
                  })}
                  {/* <option value="teste">teste</option> */}
                </select>
              </div>
            </div>
            <div className="sub-category">
              {subCategoryFiltered
                ?.slice(
                  0,
                  flagBtn && idFilter === "DEFAULT"
                    ? subCategoryFiltered.length
                    : subCategoryFiltered.length
                )
                ?.filter((value) => {
                  if (idFilter !== "DEFAULT")
                    return value.id.toString() === idFilter;
                  else return value;
                })
                ?.map((e) => {
                  return (
                    <Fragment key={e?.id}>
                      <div
                        id={"subcategoria" + String(e?.id)}
                        key={e?.id}
                        className={
                          idSubCategorySelected
                            ? "border-bottom header-content"
                            : "header-content"
                        }
                      >
                        <span
                          onClick={() => {
                            handleIdSubcategoria(e?.id);
                            anchoringSubcategory(e?.id);
                          }}
                        >
                          <button className="open-services">
                            {e?.nome}
                            <img
                              src={
                                idSubCategorySelected
                                  ? iconArrowUp
                                  : iconSelectImg
                              }
                              alt="Ver serviços"
                            />
                          </button>

                          {idSubCategorySelected === e?.id && <hr />}
                        </span>
                      </div>

                      {idSubCategorySelected === e?.id && (
                        <div className="list-cards-exams">
                          <Exame
                            handleButtonToContinue={handleButtonToContinue}
                            idSubCategorySelected={idSubCategorySelected}
                          />
                        </div>
                      )}
                    </Fragment>
                  );
                })}
            </div>
            {/* {flagBtn && idFilter === 'DEFAULT' ?
                            //<button className="more-categories" onClick={() => setFlagBtn(false) }>Ver mais categorias</button>
                            null
                            :
                            null
                        } */}
          </>
        )}
        {!loading && window.innerWidth < 1025 && (
          <div
            className="row header-sub-category"
            style={{
              position: "fixed",
              bottom: 0,
              backgroundColor: "#ffffff",
              width: "100%",
              height: "70px",
              boxShadow: "0px 0px 10px rgba(0, 24, 48, 0.1)",
            }}
          >
            <div className="col-12">
              <S.ContainerButton style={{ width: "25%" }}>
                <>
                  <Button
                    style={{ margin: 0 }}
                    disabled={agendamentoContext.exames.length === 0}
                    nextButtonActive={nextButtonActive}
                    context={agendamentoContext}
                    onClick={() => {
                      continueButton();
                    }}
                  />
                </>
              </S.ContainerButton>
            </div>
          </div>
        )}
      </S.Container>
    </>
  );
}
